@import "node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "node_modules/bootstrap/scss/_images.scss";
@import "node_modules/bootstrap/scss/utilities";

//Typography
@font-face {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 100 300;
  font-display: swap;
  src: local("Circular Std"),
    url("./static/fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Circular Std";
  font-style: italic;
  font-weight: 100 300;
  font-display: swap;
  src: local("Circular Std"),
    url("./static/fonts/CircularStd-BookItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Circular Std Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Circular Std Medium"),
    url("./static/fonts/CircularStd-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Circular Std Medium";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Circular Std Medium"),
    url("./static/fonts/CircularStd-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Circular Std Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Circular Std Bold"),
    url("./static/fonts/CircularStd-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Circular Std Bold";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Circular Std Bold"),
    url("./static/fonts/CircularStd-BoldItalic.ttf") format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  vertical-align: unset;
}

#root {
  height: 100%;
}

.ant-tabs-tab {
opacity: 0.55;
}

.ant-tabs-tab.ant-tabs-tab-active {
  color: #0F61D9 !important;
  opacity: 1;
}

.ant-tabs-ink-bar {
  background: #0F61D9 !important;
}

.text-color {
  opacity: 0.55;
}

.font-family-medium {
  font-family: 'Circular Std Medium', sans-serif;
  font-weight: 500 !important;
}

.font-family-bold {
  font-family: 'Circular Std Bold', sans-serif;
}

/* h1,h2,h3,h4,h5,h6 {
  font-family: 'Circular Std Medium', sans-serif ;
} */

.btn-link {
  color: #fff;
  font-weight: bold;
  background-color: #1561d9;
  padding: .3rem .5rem;
  border-radius: .6rem;
  border: 1px solid transparent;
}
.btn-link:hover {
  // text-decoration: underline;
  color: #1561d9;
  background: transparent;
  border: 1px solid #1561d9;
}